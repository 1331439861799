import React from 'react';
import { Box } from '@quarkly/widgets';

const NetlifyForm = ({
	children,
	name = 'contact',
	action = '/',
	onLoading,
	onSuccess,
	onError
}) => {
	const handleSubmit = async event => {
		event.preventDefault();
		if (onLoading) onLoading(true);
		if (onError) onError(false);
		if (onSuccess) onSuccess(false);
		const formData = new FormData(event.target);
		const data = new URLSearchParams(formData);

		try {
			const response = await fetch(event.target.action, {
				method: 'POST',
				body: data
			});

			if (response.ok) {
				if (onSuccess) onSuccess(true);
			} else {
				throw new Error('Form submission failed');
			}
		} catch (error) {
			if (onError) onError(true);
		} finally {
			if (onLoading) onLoading(false);
		}
	};

	return <Box
		as="form"
		name={name}
		method="POST"
		data-netlify="true"
		data-netlify-honeypot="bot-field"
		action={action}
		onSubmit={handleSubmit}
	>
		            
		{
			/* Hidden input for form-name */
		}
		            
		<input type="hidden" name="form-name" value={name} />
		            
		{
			/* Honey pot field to catch bots */
		}
		            
		<input type="hidden" name="bot-field" />
		            
		{children}
		        
	</Box>;
};

export default NetlifyForm;