import React, { useState } from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Input, Button, Box } from "@quarkly/widgets";
import NetlifyForm from './NetlifyForm';
const defaultProps = {
	"width": "100%"
};
const overrides = {
	"HeadingText": {
		"kind": "Text",
		"props": {
			"sm-text-align": "center",
			"margin": "0px 0px 8px 0px",
			"color": "--light",
			"text-align": "left",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"as": "h2",
			"children": "Let's connect today!",
			"font": "--headline3"
		}
	},
	"SubHeadingText": {
		"kind": "Text",
		"props": {
			"sm-margin": "0px 0px 12px 0px",
			"sm-text-align": "center",
			"margin": "0px 0px 24px 0px",
			"font": "--lead",
			"color": "--lightD2",
			"text-align": "left",
			"sm-font": "normal 400 16px/1.5 \"Source Sans Pro\", sans-serif",
			"children": "Looking for expert financial guidance in the Netherlands?"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 16px 0px",
			"font": "--lead",
			"color": "--lightD2",
			"sm-margin": "0px 0px 8px 0px",
			"children": "Name"
		}
	},
	"input": {
		"kind": "Input",
		"props": {
			"required": true,
			"placeholder": "Your name",
			"border-radius": "4px",
			"border-style": "solid",
			"width": "100%",
			"name": "Name",
			"margin": "0px 0px 24px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-width": "2px",
			"padding": "10px 16px 10px 16px",
			"type": "text",
			"sm-margin": "0px 0px 14px 0px",
			"color": "--light"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"sm-margin": "0px 0px 8px 0px",
			"margin": "0px 0px 16px 0px",
			"font": "--lead",
			"color": "--lightD2",
			"children": "E-mail"
		}
	},
	"input1": {
		"kind": "Input",
		"props": {
			"required": true,
			"placeholder": "Your email address",
			"border-radius": "4px",
			"border-style": "solid",
			"width": "100%",
			"sm-margin": "0px 0px 14px 0px",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-width": "2px",
			"padding": "10px 16px 10px 16px",
			"name": "Email",
			"type": "email",
			"margin": "0px 0px 24px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"color": "--light"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 16px 0px",
			"font": "--lead",
			"color": "--lightD2",
			"sm-margin": "0px 0px 8px 0px",
			"children": "Message"
		}
	},
	"input2": {
		"kind": "Input",
		"props": {
			"required": true,
			"placeholder": "Type your message here",
			"padding": "10px 16px 10px 16px",
			"name": "Text",
			"type": "text",
			"margin": "0px 0px 32px 0px",
			"min-height": "144px",
			"border-radius": "4px",
			"border-width": "2px",
			"width": "100%",
			"as": "textarea",
			"sm-margin": "0px 0px 20px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-style": "solid",
			"color": "--light"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "flex-end"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"width": "100%",
			"font": "normal 600 22px/1.5 --fontFamily-googleInter",
			"border-radius": "4px",
			"padding": "9px 82px 9px 81px",
			"background": "--color-utOrange"
		}
	}
};

const EmailForm = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	return <Box {...rest}>
		{!success && <>
			<Text {...override('HeadingText')} />
			<Text {...override('SubHeadingText')} />
		</>}
		{success && <Text {...override('SuccessMessage')}>
			Enquiry has been sent successfully. We'll get back to you soon.
		</Text>}
		{loading && <Text {...override('LoadingMessage')}>
			Sending...
		</Text>}
		{error && <Text {...override('ErrorMessage')}>
			{error}
		</Text>}
		{!success && <NetlifyForm name="generalForm" onLoading={setLoading} onSuccess={setSuccess} onError={setError}>
			<Text {...override("text")} />
			<Input {...override("input")} />
			<Text {...override("text1")} />
			<Input {...override("input1")} />
			<Text {...override("text2")} />
			<Input {...override("input2")} />
			<Box {...override("box")}>
				<Button {...override("button")}>
					Send
				</Button>
			</Box>
			{children}
		</NetlifyForm>}
	</Box>;
};

Object.assign(EmailForm, { ...NetlifyForm,
	defaultProps,
	overrides
});
export default EmailForm;