import React, { useState, useEffect } from 'react';
import { Text, Input, Button, Box } from '@quarkly/widgets';
import { useOverrides } from "@quarkly/components";
import Select from 'react-select';
import NetlifyForm from './NetlifyForm';
const defaultProps = {
	"width": "100%"
};
const overrides = {
	"HeadingText": {
		"kind": "Text",
		"props": {
			"sm-text-align": "center",
			"margin": "0px 0px 8px 0px",
			"color": "--light",
			"text-align": "left",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"as": "h2",
			"font": "--headline2",
			"children": "Let's connect today!"
		}
	},
	"SubHeadingText": {
		"kind": "Text",
		"props": {
			"sm-margin": "0px 0px 12px 0px",
			"sm-text-align": "center",
			"margin": "0px 0px 24px 0px",
			"font": "--lead",
			"color": "--lightD2",
			"text-align": "left",
			"sm-font": "normal 400 16px/1.5 \"Source Sans Pro\", sans-serif",
			"children": "Looking for expert financial guidance in the Netherlands?"
		}
	},
	"ReferenceInput": {
		"kind": "Input",
		"props": {
			"name": "reference:",
			"type": "hidden"
		}
	},
	"InputCompany": {
		"kind": "Input",
		"props": {
			"required": true,
			"placeholder": "Company's Name*",
			"border-radius": "4px",
			"border-style": "solid",
			"width": "100%",
			"name": "business:",
			"sm-margin": "0px 0px 14px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-width": "2px",
			"padding": "10px 16px",
			"color": "--light",
			"margin": "0 0 14px 0"
		}
	},
	"InputName": {
		"kind": "Input",
		"props": {
			"required": true,
			"placeholder": "First Contact's Name*",
			"border-radius": "4px",
			"border-style": "solid",
			"width": "100%",
			"name": "name:",
			"sm-margin": "0px 0px 14px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-width": "2px",
			"padding": "10px 16px",
			"color": "--light",
			"margin": "0 0 14px 0"
		}
	},
	"InputEmail": {
		"kind": "Input",
		"props": {
			"required": true,
			"placeholder": "Email Address*",
			"border-radius": "4px",
			"border-style": "solid",
			"width": "100%",
			"sm-margin": "0px 0px 14px 0px",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-width": "2px",
			"padding": "10px 16px",
			"name": "email:",
			"type": "email",
			"background": "rgba(255, 255, 255, 0)",
			"color": "--light",
			"margin": "0 0 14px 0"
		}
	},
	"InputPhone": {
		"kind": "Input",
		"props": {
			"required": false,
			"placeholder": "Phone Number",
			"border-radius": "4px",
			"border-style": "solid",
			"width": "100%",
			"sm-margin": "0px 0px 14px 0px",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-width": "2px",
			"padding": "10px 16px",
			"type": "tel",
			"name": "phone:",
			"background": "rgba(255, 255, 255, 0)",
			"color": "--light",
			"margin": "0 0 14px 0"
		}
	},
	"InputService": {
		"kind": "Select",
		"props": {
			"required": true,
			"isMulti": true,
			"options": [{
				value: 'comprehensive_financial_management',
				label: 'Comprehensive Financial Management'
			}, {
				value: 'payroll',
				label: 'Payroll'
			}, {
				value: 'vat_returns',
				label: 'VAT Returns'
			}, {
				value: 'tax_administration',
				label: 'Tax Administration'
			}, {
				value: 'financial_reporting',
				label: 'Financial Reportings'
			}, {
				value: 'audit_assistance',
				label: 'Audit Assistance'
			}, {
				value: 'cash_flow_&_automation_solutions',
				label: 'Cash Flow & Automation Solutions'
			}, {
				value: 'expert_advisory',
				label: 'Expert Advisory'
			}, {
				value: 'other_specify',
				label: 'Other (specify)'
			}],
			"placeholder": "Services you require*",
			"styles": {
				control: base => ({ ...base,
					background: "rgba(255, 255, 255, 0)",
					borderColor: "rgba(228, 232, 236, 0.25)",
					color: "--light",
					width: "100%",
					padding: "10px 16px",
					margin: "0px 0px 14px 0px",
					borderRadius: "4px",
					borderWidth: "2px",
					borderStyle: "solid",
					"&:hover": {
						borderColor: "rgb(197,234,252)"
					}
				}),
				menu: base => ({ ...base,
					background: "--dark",
					color: "--light"
				}),
				option: base => ({ ...base,
					background: "--dark",
					color: "--light",
					"&:hover": {
						background: "--light",
						color: "--dark"
					}
				})
			}
		}
	},
	"InputMessage": {
		"kind": "Input",
		"props": {
			"required": false,
			"placeholder": "Type your message here",
			"padding": "10px 16px",
			"name": "message:",
			"as": "textarea",
			"margin": "0px 0px 32px 0px",
			"min-height": "144px",
			"border-radius": "4px",
			"border-width": "2px",
			"width": "100%",
			"sm-margin": "0px 0px 20px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-style": "solid",
			"color": "--light"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "flex-end",
			"flex-direction": "column"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"width": "100%",
			"font": "normal 600 22px/1.5 --fontFamily-googleInter",
			"border-radius": "4px",
			"padding": "9px 82px 9px 81px",
			"background": "--color-utOrange",
			"transition": "all 0.5s ease 0s",
			"hover-transform": "scale(1.05)",
			"focus-background": "--color-secondary",
			"aria-label": "Submit"
		}
	},
	"Fields1Wrapper": {
		"props": {
			"display": "grid",
			"grid-template-columns": "1fr"
		}
	},
	"NameLabel": {
		"props": {
			"font": "--base"
		}
	},
	"EmailLabel": {
		"props": {
			"font": "--base"
		}
	},
	"PhoneLabel": {
		"props": {
			"font": "--base"
		}
	},
	"ServiceLabel": {
		"props": {
			"font": "--base"
		}
	},
	"MessageLabel": {
		"props": {
			"font": "--base"
		}
	},
	"SuccessMessage": {
		"props": {
			"font": "--lead",
			"color": "--lightD2",
			"text-align": "center"
		}
	},
	"LoadingMessage": {
		"props": {
			"font": "--lead",
			"text-align": "center",
			"color": "--lightD2"
		}
	},
	"ErrorMessage": {
		"props": {
			"font": "--lead",
			"text-align": "center",
			"color": "--lightD2"
		}
	},
	"RequiredDescription": {
		"props": {
			"font": "normal 400 15px/1 --fontFamily-googleInter",
			"color": "--light",
			"children": <>
				Items marked with an * are required fields.{"\n\n"}
			</>
		}
	}
};

const CorporateForm = props => {
	const [selectedServices, setSelectedServices] = useState('');
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const handleSelectChange = selectedOptions => {
		setSelectedServices(selectedOptions ? selectedOptions.map(option => option.value).join(', ').replace(/_/g, ' ') : []);
	};

	useEffect(() => {
		console.log("Selected options ", selectedServices);
	}, [selectedServices]);
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		{!success && <>
			<Text {...override('HeadingText')} />
			<Text {...override('SubHeadingText')} />
		</>}
		{success && <Text {...override('SuccessMessage')}>
			Enquiry has been sent successfully. We'll get back to you soon.
		</Text>}
		{loading && <Text {...override('LoadingMessage')}>
			Sending...
		</Text>}
		{error && <Text {...override('ErrorMessage')}>
			{error}
		</Text>}
		{!success && <NetlifyForm name="CorporateForm" onLoading={setLoading} onSuccess={setSuccess} onError={setError}>
			<Box {...override("Fields1Wrapper")}>
				<Input {...override('ReferenceInput')} value={props.reference} />
				<Input {...override("InputCompany")} />
				<Input {...override("InputName")} />
				<Input {...override("InputEmail")} />
				<Input {...override("InputPhone")} />
				<Select {...override("InputService")} onChange={handleSelectChange} />
				<Input type="hidden" name="services:" value={selectedServices} />
				<Input {...override("InputMessage")} />
			</Box>
			<Box {...override("box")}>
				<Button {...override("button")} />
				<Text {...override('RequiredDescription')} />
			</Box>
			{children}
		</NetlifyForm>}
	</Box>;
};

const propInfo = {
	reference: {
		title: "Email Reference",
		type: "text",
		category: "Main"
	}
};
Object.assign(CorporateForm, { ...Box,
	defaultProps,
	overrides,
	propInfo
});
export default CorporateForm;