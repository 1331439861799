import React from 'react';
import { useOverrides } from '@quarkly/components';
import { Box, Input } from '@quarkly/widgets';

const MonthYearPicker = ({
	name,
	value = '2000-01',
	onChange,
	...props
}) => {
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	const years = Array.from(new Array(50), (val, index) => index + 1980); // Adjust the range as needed

	const handleMonthChange = e => {
		const [year] = value.split('-');
		const newDate = `${year}-${e.target.value}`;
		onChange(newDate);
	};

	const handleYearChange = e => {
		const [, month] = value.split('-');
		const newDate = `${e.target.value}-${month}`;
		onChange(newDate);
	};

	const [year = '2000', month = '01'] = value ? value.split('-') : [];
	const {
		override
	} = useOverrides(props, {}, {});
	return <Box {...override('box')}>
		            
		<Input as="select" value={month} onChange={handleMonthChange} {...override('monthSelect')}>
			                
			{months.map((monthName, index) => <option key={index} value={String(index + 1).padStart(2, '0')}>
				{monthName}
			</option>)}
			            
		</Input>
		            
		<Input as="select" value={year} onChange={handleYearChange} {...override('yearSelect')}>
			                
			{years.map(year => <option key={year} value={year}>
				{year}
			</option>)}
			            
		</Input>
		            
		<Input type="hidden" name={name} value={value} />
		        
	</Box>;
};

export default MonthYearPicker;