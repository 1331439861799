import React, { useState, useEffect } from 'react';
import { Text, Input, Button, Box } from '@quarkly/widgets';
import { useOverrides } from "@quarkly/components";
import Select from 'react-select';
import NetlifyForm from './NetlifyForm';
import MonthYearPicker from './MonthYearPicker';
const defaultProps = {
	"width": "100%"
};
const overrides = {
	"HeadingText": {
		"kind": "Text",
		"props": {
			"sm-text-align": "center",
			"margin": "0px 0px 8px 0px",
			"color": "--light",
			"text-align": "left",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"as": "h2",
			"font": "--headline2",
			"children": "会計に関するご相談がございましたら、お気軽にご連絡ください。"
		}
	},
	"SubHeadingText": {
		"kind": "Text",
		"props": {
			"sm-margin": "0px 0px 12px 0px",
			"sm-text-align": "center",
			"margin": "0px 0px 24px 0px",
			"font": "--lead",
			"color": "--lightD2",
			"text-align": "left",
			"sm-font": "normal 400 16px/1.5 \"Source Sans Pro\", sans-serif",
			"children": "オランダの公認会計士が専門的なアドバイスを行います。"
		}
	},
	"ReferenceInput": {
		"kind": "Input",
		"props": {
			"name": "reference:",
			"type": "hidden"
		}
	},
	"InputName": {
		"kind": "Input",
		"props": {
			"required": true,
			"placeholder": "名前*",
			"border-radius": "4px",
			"border-style": "solid",
			"width": "100%",
			"name": "name:",
			"sm-margin": "0px 0px 14px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-width": "2px",
			"padding": "10px 16px",
			"color": "--light",
			"margin": "0 0 14px 0"
		}
	},
	"InputEmail": {
		"kind": "Input",
		"props": {
			"required": true,
			"placeholder": "Eメール*",
			"border-radius": "4px",
			"border-style": "solid",
			"width": "100%",
			"sm-margin": "0px 0px 14px 0px",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-width": "2px",
			"padding": "10px 16px",
			"name": "email:",
			"type": "email",
			"background": "rgba(255, 255, 255, 0)",
			"color": "--light",
			"margin": "0 0 14px 0"
		}
	},
	"InputPhone": {
		"kind": "Input",
		"props": {
			"required": false,
			"placeholder": "電話番号",
			"border-radius": "4px",
			"border-style": "solid",
			"width": "100%",
			"sm-margin": "0px 0px 14px 0px",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-width": "2px",
			"padding": "10px 16px",
			"type": "tel",
			"name": "phone:",
			"background": "rgba(255, 255, 255, 0)",
			"color": "--light",
			"margin": "0 0 14px 0"
		}
	},
	"ArrivalLabel": {
		"kind": "Text",
		"props": {
			"color": "--lightD2",
			"sm-margin": "0px 0px 8px 0px",
			"font": "--base",
			"margin": "0px 0px 14px 0px",
			"children": "いつオランダに来ましたか？"
		}
	},
	"InputArrival": {
		"kind": "MonthYearPicker",
		"props": {
			"required": true,
			"name": "arrival:",
			//"placeholder": "2010-01",
			"border-radius": "4px",
			"border-style": "solid",
			"width": "100%",
			"margin": "0px 0px 24px 0px",
			"sm-margin": "0px 0px 14px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-width": "2px",
			"padding": "10px 16px",
			"color": "--light"
		},
		"overrides": {
			"box": {
				"display": "flex",
				"flex-direction": "row",
				"gap": "8px",
				"props": {
					"border-color": "rgba(228,232,236,0.25)",
					"border-width": "2px",
					"border-style": "solid",
					"hover-border-color": "--color-lightD2",
					"display": "flex",
					"margin": "0 0 14px 0"
				}
			},
			"monthSelect": {
				"border-radius": "4px",
				"border-style": "solid",
				"border-color": "rgba(228, 232, 236, 0.25)",
				"border-width": "2px",
				"padding": "10px 16px",
				"background": "rgba(255, 255, 255, 0)",
				"color": "--light",
				"props": {
					"color": "--light",
					"background": "--color-darkL2",
					"border-color": "--color-lightD2",
					"border-width": 0
				}
			},
			"yearSelect": {
				"border-radius": "4px",
				"border-style": "solid",
				"border-color": "rgba(228, 232, 236, 0.25)",
				"border-width": "2px",
				"padding": "10px 16px",
				"background": "rgba(255, 255, 255, 0)",
				"color": "--light",
				"props": {
					"background": "--color-darkL2",
					"color": "--light",
					"border-width": 0
				}
			}
		}
	},
	"InputService": {
		"kind": "Select",
		"props": {
			"required": true,
			"isMulti": true,
			"options": [{
				value: 'general_bookkeeping',
				label: '一般会計業務'
			}, {
				value: 'balance_sheet',
				label: 'バランスシート'
			}, {
				value: 'vat_returns',
				label: 'VAT申告'
			}, {
				value: 'year_end_accounts',
				label: '年末決算のまとめ'
			}, {
				value: 'annual_income_tax_returns',
				label: '年間所得税申告'
			}, {
				value: 'tax_forms_completion',
				label: '会計に関連したフォームの作成'
			}, {
				value: 'advice_and_support',
				label: 'アドバイスとサポート'
			}, {
				value: 'other_specify',
				label: 'その他（具体的に記入）'
			}],
			"placeholder": "ご希望のサービス*",
			"styles": {
				control: base => ({ ...base,
					background: "rgba(255, 255, 255, 0)",
					borderColor: "rgba(228, 232, 236, 0.25)",
					color: "--light",
					width: "100%",
					padding: "10px 16px",
					margin: "0px 0px 14px 0px",
					borderRadius: "4px",
					borderWidth: "2px",
					borderStyle: "solid",
					"&:hover": {
						borderColor: "rgb(197,234,252)"
					}
				}),
				menu: base => ({ ...base,
					background: "--dark",
					color: "--light"
				}),
				option: base => ({ ...base,
					background: "--dark",
					color: "--light",
					"&:hover": {
						background: "--light",
						color: "--dark"
					}
				})
			}
		}
	},
	"InputMessage": {
		"kind": "Input",
		"props": {
			"required": false,
			"placeholder": "ここにメッセージを入力してください。",
			"padding": "10px 16px",
			"name": "message:",
			"as": "textarea",
			"margin": "0px 0px 32px 0px",
			"min-height": "144px",
			"border-radius": "4px",
			"border-width": "2px",
			"width": "100%",
			"sm-margin": "0px 0px 20px 0px",
			"background": "rgba(255, 255, 255, 0)",
			"border-color": "rgba(228, 232, 236, 0.25)",
			"border-style": "solid",
			"color": "--light"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "flex-end",
			"flex-direction": "column"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"width": "100%",
			"font": "normal 600 22px/1.5 --fontFamily-googleInter",
			"border-radius": "4px",
			"padding": "9px 82px 9px 81px",
			"background": "--color-utOrange",
			"transition": "all 0.5s ease 0s",
			"hover-transform": "scale(1.05)",
			"focus-background": "--color-secondary"
		}
	},
	"Fields1Wrapper": {
		"props": {
			"display": "grid",
			"grid-template-columns": "1fr"
		}
	},
	"NameLabel": {
		"props": {
			"font": "--base"
		}
	},
	"EmailLabel": {
		"props": {
			"font": "--base"
		}
	},
	"PhoneLabel": {
		"props": {
			"font": "--base"
		}
	},
	"ServiceLabel": {
		"props": {
			"font": "--base"
		}
	},
	"MessageLabel": {
		"props": {
			"font": "--base"
		}
	},
	"SuccessMessage": {
		"props": {
			"font": "--lead",
			"color": "--lightD2",
			"text-align": "center"
		}
	},
	"LoadingMessage": {
		"props": {
			"font": "--lead",
			"text-align": "center",
			"color": "--lightD2"
		}
	},
	"ErrorMessage": {
		"props": {
			"font": "--lead",
			"text-align": "center",
			"color": "--lightD2"
		}
	},
	"RequiredDescription": {
		"props": {
			"font": "normal 400 15px/1 --fontFamily-googleInter",
			"color": "--light",
			"children": <>
				Items marked with an * are required fields.{"\n\n"}
			</>
		}
	}
};

const FreelancerFormJP = props => {
	const [selectedServices, setSelectedServices] = useState('');
	const [arrivalDate, setArrivalDate] = useState('2024-01');
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const handleSelectChange = selectedOptions => {
		setSelectedServices(selectedOptions ? selectedOptions.map(option => option.value).join(', ').replace(/_/g, ' ') : []);
	};

	useEffect(() => {
		console.log("Selected options ", selectedServices);
	}, [selectedServices]);

	const handleArrivalDateChange = newDate => {
		setArrivalDate(newDate);
	};

	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		{!success && <>
			<Text {...override('HeadingText')} />
			<Text {...override('SubHeadingText')} />
		</>}
		{success && <Text {...override('SuccessMessage')}>
			お問い合わせは正常に送信されました。返答までしばらくお待ちください。
		</Text>}
		{loading && <Text {...override('LoadingMessage')}>
			送信中...
		</Text>}
		{error && <Text {...override('ErrorMessage')}>
			{error}
		</Text>}
		{!success && <NetlifyForm name="freelancerForm" onLoading={setLoading} onSuccess={setSuccess} onError={setError}>
			<Box {...override("Fields1Wrapper")}>
				<Input {...override('ReferenceInput')} value={props.reference} />
				<Input {...override("InputName")} />
				<Input {...override("InputEmail")} />
				<Input {...override("InputPhone")} />
				<Text {...override("ArrivalLabel")} />
				<MonthYearPicker {...override("InputArrival")} value={arrivalDate} onChange={handleArrivalDateChange} />
				<Select {...override("InputService")} onChange={handleSelectChange} />
				<Input type="hidden" name="services:" value={selectedServices} />
				<Input {...override("InputMessage")} />
			</Box>
			<Box {...override("box")}>
				<Button {...override("button")} />
				<Text {...override('RequiredDescription')} />
			</Box>
			{children}
		</NetlifyForm>}
	</Box>;
};

const propInfo = {
	reference: {
		title: "Email Reference",
		type: "text",
		category: "Main"
	}
};
Object.assign(FreelancerFormJP, { ...Box,
	defaultProps,
	overrides,
	propInfo
});
export default FreelancerFormJP;